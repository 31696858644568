import React, { FunctionComponent } from 'react'
import {SentenceElementProps} from '../types/custom-types'

const colorOfFlesch = (fleschValue: number | null) => {
    if (fleschValue) {
        if (fleschValue < 50) {
            return 'rgb(200,100,100)'
        } else if (fleschValue < 60) {
            return 'rgb(255,150,150)'
        } else if (fleschValue < 70) {
            return 'rgb(255,170,170)'
        } else if (fleschValue < 80) {
            return 'rgb(230,230,255)'
        } else if (fleschValue < 90) {
            return 'rgb(200,255,255)'
        } else if (fleschValue < 100) {
            return 'rgb(200,230, 200 )'
        } else if (fleschValue < 100) {
            return 'rgb(200,255,200 )'
        }
    } else {
        return 'rgb(255,255,255)'
    }
}

export const SentenceElement: FunctionComponent<SentenceElementProps> = ({ attributes, children, element }) => {
    const flesch: number = element.flesch;

    return (
        <span
            {...attributes}
            style={{
                marginLeft: '0.5rem',
                borderLeft: '0.05rem solid black',
                backgroundColor: flesch ? colorOfFlesch(flesch) : 'transparent'
            }}
            title={flesch}
        >
        {children}
        </span>
    )
}

export default SentenceElement
