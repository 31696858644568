import './App.css'

// Import React dependencies.
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
// Import the Slate editor factory.
import {createEditor, Editor, Node, Transforms} from 'slate'

// Import the Slate components and React plugin.
import {Slate, Editable, withReact, RenderLeafProps, RenderElementProps} from 'slate-react'
import {Element as SlateElement} from "slate"

import EditorLeaf from './components/EditorLeaf'
import EditorElement from './components/EditorElement'

// Slate types
import {Descendant} from 'slate'

import {SentenceMetrics} from "./types/custom-types";

const App = () => {
    const refreshInfoTimeout: React.RefObject<ReturnType<typeof setTimeout> | null> = useRef(null)


    const isProcessing = useRef(false);

    const [initialValue] = useState<Descendant[]>(
        [
            {
                type: 'paragraph',
                children: [
                    {
                        type: 'sentence', children: [
                            {text: 'X'}
                        ],
                        flesch: null,
                        lastText: null
                    }],
            }
        ]
    )

    const withSentences = (editor: Editor) => {
        const {isInline, isVoid} = editor

        const customElementBehavior: { [key: string]: { [key: string]: boolean; } } = {
            "paragraph": {isInline: false, isVoid: false},
            "sentence": {isInline: false, isVoid: false}
        }
        editor.isInline = (element: SlateElement) => {
            if (element.type in customElementBehavior) {
                return customElementBehavior[element.type]['isInline']
            } else {
                return isInline(element)
            }
        }

        editor.isVoid = (element: SlateElement) => {
            if (element.type in customElementBehavior) {
                return customElementBehavior[element.type]['isVoid']
            } else {
                return isVoid(element)
            }
        }

        return editor
    }

    const editor = useMemo(
        () => withSentences(withReact(createEditor())),
        []
    )


    const applyMetrics = useCallback(
        async (
            sentenceMetrics: SentenceMetrics,
            editor: Editor) => {

            // Match the existing SentenceElement nodes
            // with the sentence returned by the server
            sentenceMetrics['sentenceIndexes'].forEach((sentenceIndex, index) => {
                const singleSentenceText = sentenceMetrics['originalText'].substring(sentenceIndex[0], sentenceIndex[1])
                Transforms.insertNodes(
                    editor,
                    {
                        type: 'sentence',
                        flesch: sentenceMetrics['flesch']['countForText'][index],
                        children: [{text: singleSentenceText}],
                        lastText: singleSentenceText
                    },
                    {at: [editor.children.length]}
                );
            })
        }, [])

    const cleanupEditor = useCallback(() => {
        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, [])
            }
        });
    }, [editor])

    const refreshInfo = useCallback(async (nodes: Node[]) => {
        if (isProcessing.current) {
            return
        }
        try {
            isProcessing.current = true;

            cleanupEditor()

            // Add loading indicator
            Transforms.insertNodes(
                editor,
                {
                    type: 'loading',
                    children: [{ text: '...načítám...' }],
                },
                { at: [editor.children.length] }
            );


            const text = nodes.map(node => Node.string(node)).join("")

            const url = process.env.REACT_APP_TEXT_COMPLEXITY_API_URL + "/flesch"

            const fetchResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({texts: [text]})
            })
            const responseMetrics = await fetchResponse.json()
            cleanupEditor()
            await applyMetrics(responseMetrics[0], editor)
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            isProcessing.current = false;
        }
    }, [applyMetrics, editor, cleanupEditor])

    const handleEditorChange = useCallback(async (_value: Node[], editor: Editor) => {
        const isAstChange = editor.operations.some(
            (op: any) => op.type !== 'set_selection'
        )

        if (isAstChange) {
            await refreshInfo(editor.children)
        }
    }, [refreshInfo])

    /* Update the info from server when the text was changed*/
    const DEBOUNCE_REFRESH_INFO_MS = 1000

    useEffect(() => {
        // https://devtrium.com/posts/async-functions-useeffect
        const refreshInfoWrapper = async () => {
            await refreshInfo(editor.children)
        }

        // debouncing
        if (refreshInfoTimeout.current !== null) {
            clearTimeout(refreshInfoTimeout.current)
            refreshInfoTimeout.current = null
        }
        refreshInfoTimeout.current = setTimeout(
            function () {
                refreshInfoTimeout.current = null
                refreshInfoWrapper().catch(console.error)
            }, DEBOUNCE_REFRESH_INFO_MS
        )

        // cleanup method
        return () => {
            if (refreshInfoTimeout.current !== null) {
                clearTimeout(refreshInfoTimeout.current)
                refreshInfoTimeout.current = null
            }
        }
    }, [editor.children, refreshInfo]);


    const renderElement = useCallback((props: RenderElementProps) => <EditorElement {...props}
                                                                                    children={props.children} />, [])
    const renderLeaf = useCallback((props: RenderLeafProps) => <EditorLeaf {...props} children={props.children} />, [])

    return (
        <div className="app">
            <Slate
                editor={editor}
                initialValue={initialValue}
                onChange={value => handleEditorChange(value, editor)}
            >
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                />
            </Slate>
        </div>
    )
}

export default App
